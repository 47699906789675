import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Fyndorich Fields
			</title>
			<meta name={"description"} content={"Her maçın ev sahibi gibi hissedildiği yer"} />
			<meta property={"og:title"} content={"Fyndorich"} />
			<meta property={"og:description"} content={"Her maçın ev sahibi gibi hissedildiği yer"} />
			<meta property={"og:image"} content={"https://fyndorich.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fyndorich.com/img/5043560.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fyndorich.com/img/5043560.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fyndorich.com/img/5043560.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fyndorich.com/img/5043560.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fyndorich.com/img/5043560.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fyndorich.com/img/5043560.png"} />
		</Helmet>
		<Components.Header />
		<Section  background="linear-gradient(180deg,rgba(11, 15, 59, 0.81) 0%,rgba(11, 15, 59, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://fyndorich.com/img/1.jpg) 0% 70% /cover repeat scroll padding-box" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text as="h1" margin="12px 0" font="--headline2" md-font="--headline3">
							Bizim Hikayemiz
						</Text>
						<Text
							as="p"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Fyndorich Sahalarında, oynadığınız her maçın bir oyundan daha fazlası olduğuna, anlatılmayı bekleyen bir hikaye olduğuna inanıyoruz. Sahamız zafer hikayelerinin, dostluğun ve futbolun saf neşesinin hayat bulduğu bir yer olarak tasarlandı. Futbol tutkunları için ortak bir alan sağlama vizyonuyla kurulan sahamız sayısız gole, kutlanan zaferlere ve paylaşılan sportmenlik anlarına tanıklık etti.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://fyndorich.com/img/3.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Text
				margin="0px 0px 50px 0px"
				font="--headline2"
				color="--dark"
				sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				text-align="center"
			>
				Fyndorich Neden Öne Çıkıyor?
			</Text>
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://fyndorich.com/img/4.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--lead"
				>
					Toplum Merkezli: Yerel futbol severler için bir merkez.
					<br />
					Kusursuz Bakım: Her zaman maça hazır bir saha.
					<br />
					Erişilebilirlik: Gün doğumundan stadyum ışıkları sönene kadar açıktır.
					<br />
					Gizlilik: Rezervasyonlar grubunuz için özel erişimi garanti eder.
					<br />
					Basitlik: Sorunsuz online rezervasyon sistemi.


				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
				justify-content="space-between"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--lead" sm-text-align="left">
						Sizin hikayeniz bizim mirasımızdır. İster dostça bir vuruş ister kıyasıya bir rekabet olsun, Fyndorich'teki her an, ortak futbol hikayemizin zengin dokusuna katkıda bulunur. Bölümünüzü oluşturun, becerilerinizi geliştirin ve sahanın ötesinde yankılanacak anlar yaratın. Her maçın bir maçtan daha fazlası olduğu Fyndorich'i seçin - efsanelerin yaratıldığı ve hatırlandığı yer.
					</Text>
					<Button href="/contacts" type="link" text-decoration-line="initial">
						Mirasımıza Katılın
					</Button>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://fyndorich.com/img/5.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});